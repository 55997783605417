import React, { Component, useState, useEffect } from 'react'
import { Carousel, Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

import CarouselAudioBook from '../../../content/images/carousel/carousel-audio-book.jpeg'
import Carousel1 from '../../../content/images/carousel/carousel-1.jpg'
import Carousel2 from '../../../content/images/carousel/carousel-2.png'
import Carousel3 from '../../../content/images/carousel/carousel-3.png'
import Carousel4 from '../../../content/images/carousel/carousel-5.png'
import Carousel5 from '../../../content/images/carousel/carousel-4.png'
import CarouselGenerationMedemer from '../../../content/images/carousel/carousel-generation-medemer-available.jpeg'

const getWindowDimensions = () => {
  let width = 0,
    height = 0

  if (typeof window !== `undefined`) {
    // console.log({ width: window.innerWidth, height: window.innerHeight })
    width = window.innerWidth
    height = window.innerHeight
  }

  return {
    width,
    height,
  }
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

const VideoModal = props => {
  const { height, width } = useWindowDimensions()

  // Calculate the modal height to follow a 16:9 ration
  // Note: No need to do this! I'm doing it because I'm a complete nerd :)
  const modalHeight = width / (16 / 9)

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation
      dialogClassName="modal-container custom-map-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <iframe
          width="100%"
          height={modalHeight}
          src={`https://www.youtube-nocookie.com/embed/${props.videoid}?rel=0&showinfo=0&controls=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal.Body>
    </Modal>
  )
}

class Slider extends Component {
  state = {
    modalShow: false,
    title: '',
    videoid: '',
  }

  setModalShow = (modalShow, title, videoid) =>
    this.setState({ modalShow, title, videoid })

  render() {
    const { modalShow, title, videoid } = this.state
    return (
      <>
        <Carousel fade interval={3500}>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={CarouselGenerationMedemer}
              alt="Generation Medemer book is available"
            />
            <Carousel.Caption className="text-left first">
              <p>
                <Button
                  variant="primary"
                  href="https://ethio.post/product/generation-medemer/"
                >
                  Buy the Book
                </Button>
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={Carousel1}
              alt="What does medemer mean to you?"
            />
            <Carousel.Caption className="text-left first">
              <p>
                <Button variant="primary" href="/social">
                  Tell us
                </Button>
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block w-100" src={Carousel2} alt="Third slide" />

            <Carousel.Caption className="text-left">
              <h2>Weret</h2>
              <p>
                Yesterday’s good deeds fuel today’s success and tomorrow’s
                bright future.
              </p>
              <p>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.setModalShow(true, 'Weret', 'Ce_c93V6ZrI')
                  }
                >
                  <FontAwesomeIcon icon={faPlayCircle} /> &nbsp; Watch now
                </Button>
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block w-100" src={Carousel3} alt="Third slide" />

            <Carousel.Caption className="text-left">
              <h2>Hagerawi Kurat</h2>
              <p>
                Our national pride emanates from our unique historical heritage.
              </p>
              <p>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.setModalShow(true, 'Hagerawi Kurat', '6KFIBVfmerI')
                  }
                >
                  <FontAwesomeIcon icon={faPlayCircle} /> &nbsp; Watch now
                </Button>
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block w-100" src={Carousel4} alt="Hilina" />

            <Carousel.Caption className="text-left">
              <h2>Hilina</h2>
              <p>
                Let us usher in national prosperity by eradicating moral and
                ethical degradation.
              </p>
              <p>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.setModalShow(true, 'Hilina', 'H-zDaVrlhww')
                  }
                >
                  <FontAwesomeIcon icon={faPlayCircle} /> &nbsp; Watch now
                </Button>
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block w-100" src={Carousel5} alt="Third slide" />

            <Carousel.Caption className="text-left">
              <h2>Adwa</h2>
              <p>Our success is only limited by how well we rise as one.</p>
              <p>
                <Button
                  variant="primary"
                  onClick={() => this.setModalShow(true, 'Adwa', 'KLEkKbOycA8')}
                >
                  <FontAwesomeIcon icon={faPlayCircle} /> &nbsp; Watch now
                </Button>
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <VideoModal
          show={modalShow}
          videoid={videoid}
          title={title}
          onHide={() => this.setModalShow(false)}
        />
      </>
    )
  }
}

export default Slider
